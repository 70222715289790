
<template class="dense hidden-sm-and-down">
    <div>
        <v-row v-for="x in count" :key="x">
        <v-col></v-col>
        </v-row>
    </div>
</template>

<script>

export default {
  props: {
    height: Number,
  },
  data() {
    return {
        count: this.height
    }
  }
}
</script>
