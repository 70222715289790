<template>
  <div>
    <v-main app>
      <v-container>
        <router-view></router-view>
      </v-container>
    </v-main>
  </div>
</template>

<script>

export default {
  name: "app",

  //Data
  data() {
    return {
      page: {
        url: "",
      },
      snackbarText: "",
      snackbar: false,
    };
  },

  //Lifecycle
  mounted() {
    this.$root.$on("show_snackbar", (msg) => {
      this.snackbarText = msg;
      this.snackbar = true;
    });

    this.$root.$on("show_dialog", (title, msg) => {
      this.$store.dispatch("alert/success", title, msg);
    });
  },

  //Logic
  computed: {
    notifierCookieAvailable: {
      get: function () {
        return localStorage.getItem("noNotification") != 1;
      },
    },
    dialog() {
      let v =
        this.$store.state.alert.message != "" &&
        this.$store.state.alert.message != undefined &&
        this.$store.state.alert.message != null;
      return v;
    },
    dialogMsg() {
      let msg = this.$store.state.alert.message;
      console.log(msg);
      return msg;
    },
    dialogTitle() {
      return this.$store.state.alert.title;
    },
    alert() {
      return this.$store.state.alert;
    },
  },
  methods: {
    clear() {
      this.$store.dispatch("alert/clear");
    },
  },
  watch: {
    $route() {
      this.$store.dispatch("alert/clear");
    },
  },
};
</script>

<style>
.logo-img {
  width: 320px;

  z-index: 111;
  top: 20px;
}

.theme--light {
  background: white !important;
}

.logo-block a {
  font-size: medium !important;
  margin-top: 0px;
}

.topfixure {
  top: 0 !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.nomp {
  margin-top: 0px !important;
  padding-top: 0px !important;
}

div.noslots div > div.v-text-field__details {
  display: none;
}

div.noslots div > div.v-message {
  display: none;
}
</style>

<style scoped>
.apptitlebar {
  font-family: Oswald, sans-serif;
  font-weight: 400;
  padding-top: 4px;
}
ul,
li {
  display: inline;
}
li {
  margin-left: 12px;
}

a,
li,
span,
p,
i,
b {
  font-size: medium !important;
  color: #b0b0b0 !important;
}

.logo-block {
  left: auto;
  right: auto;
}
</style>

<style>
#app {
  min-height: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 300 !important;
}

.megatitle {
  min-height: 100%;
  font-family: "Roboto", sans-serif;
  font-weight: 200 !important;
}

h2,
h3,
h4,
h5 {
  font-family: Oswald;
  /*font-weight: normal;*/
}

.color_table th {
  background-color: #778a7d !important;
  color: white;
}

.color_table th > span {
  font-weight: bold;
  color: white;
}

.color_table_secondary th {
  background-color: #878787 !important;
  color: white;
}

.color_table_secondary th > span {
  font-weight: bold;
  color: white;
}

.v-input--switch__track,
.v-input--switch__thumb {
  background-color: #778a7d !important;
}

.nopadding {
  padding: 0px;
}
</style>


<style>
.theme--light {
  background: white !important;
}

.logo-block a {
  font-size: medium !important;
  margin-top: 0px;
}

.topfixure {
  top: 0 !important;
  margin-top: 0px !important;
  padding-top: 0px !important;
}

.nomp {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>


<style scoped>
.apptitlebar {
  font-family: Oswald, sans-serif;
  font-weight: 400;
  padding-top: 4px;
}
ul,
li {
  display: inline;
}
li {
  margin-left: 12px;
}

a,
li,
span,
p,
i,
b {
  font-size: medium !important;
  color: #b0b0b0 !important;
}

.logo-block {
  left: auto;
  right: auto;
}
.nopadding {
  padding: 0px;
}
</style>
