import { offersService } from '../services';

export const offers = {
    namespaced: true,
    state: {
        all: {},
        selected: {},
        myself: {},
        default: {},
        uploaded: [],
        failed: []
    },
    actions: {
       
        resetUploads({ commit }) {
            commit('resetUploads');
        },

        init({ commit }) {
            commit('initRequest');

            console.info("Requesting init");
            return new Promise((resolve, reject) => {
                offersService.init()
                .then(
                    payload => {
                        console.info("Init request success:" + payload.token);
                        commit('initRequestSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('initRequestFailure', error)
                        reject(error)
                    } 
                )
            });
            
        },


        start({
            commit
        }, dto) {
            commit('defaultRequest');

            return new Promise((resolve, reject) => {

                console.log("Starting offer: " + dto);

                offersService.start(dto)
                    .then(
                        payload => {
                            console.log("Success: " + payload);
                            commit('defaultRequest', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('defaultRequest', error);
                            reject(error)
                        }
                    );
            })
        },

        updatePicture({
            commit
        }, image) {
            commit('defaultRequest');
            return new Promise((resolve, reject) => {
                offersService.newImage(image)
                    .then(
                        payload => {
                            commit('uploadedSuccess', image);
                            resolve(payload)
                        },
                        error => {
                            commit('uploadedFailed', image);
                            reject(error)
                        }
                    );
            })

        },
        
        createOffer({
            commit
        }, dto) {
            commit('defaultRequest');

            return new Promise((resolve, reject) => {
                offersService.createOffer(dto)
                    .then(
                        payload => {
                            commit('defaultSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('defaultFailure', error);
                            reject(error)
                        }
                    );
            })
        },
        
    },



    mutations: {

        initRequest(state) {
            state.myself = { loading: true };
        },
        initRequestSuccess(state, payload) {
            console.log("Init request success:" + payload.token);
            state.myself = { token: payload.token };
            state.myself = { token: payload.token, loading: false };
        },
        initRequestFailure(state, error) {
            state.myself = { error };
        },

        defaultRequest(state) {
            state.default = {
                loading: true
            }; 
        },
        defaultSuccess(state, payload) {
            state.default = {
                item: payload,
                loading: false
            }; 
        },
        defaultFailure(state, error) {
            state.default = {
                error,
                loading: false
            }; 
        },

        resetUploads(state) {
            state.uploaded = [];
        },

        uploadedSuccess(state, payload) {
            let info = "";
            for (var pair of payload.entries()) {
                if (pair[0] == "ProfilePicture") {
                    info = pair[1].name;
                    break;
                }
              }
            state.uploaded.push(info);
        },
        uploadedFailed(state, error) {
            let info = "";
            for (var pair of error.entries()) {
                if (pair[0] == "ProfilePicture") {
                    info = pair[1].name;
                    break;
                }
              }

            state.failed.push(info);
        },

        getRequest(state) {
            state.selected = { loading: true };
        },
        getSuccess(state, user) {
            state.selected = { item: user };
        },
        getFailure(state, error) {
            state.selected = { error };
        },
        getAllRequest(state) {
            state.all = { loading: true };
        },
        getAllSuccess(state, users) {
            state.all = { items: users };
        },
        getAllFailure(state, error) {
            state.all = { error };
        }
    }
}