//import * as Sentry from "@sentry/vue";

import {
    authHeader,
    authHeaderUndefined
} from '../helpers';

export const offersService = {
    createOffer,
    start,
    init,
    newImage,
    getAll
};


const route = '/offer'
const route_plural = '/offers'
const route_shadow = '/offering' //because of Bad Routing for save route. Workaround
const route_images = '/images'

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    console.info("Firing init request to " + process.env.VUE_APP_API_ROUTE + route + "/init");

    return fetch(process.env.VUE_APP_API_ROUTE + route_plural, requestOptions).then(handleResponse);
}

function init() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    console.info("Firing init request to " + process.env.VUE_APP_API_ROUTE + route + "/init");

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/init', requestOptions).then(handleResponse);
}

function newImage(image) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderUndefined(),
        body: image
    };

    console.info("Bild wird hochgeladen");
    console.info(requestOptions);

    return fetch(process.env.VUE_APP_API_ROUTE + route_images + '/uploadImage', requestOptions).then(handleResponse);
}


function createOffer(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route_shadow + '/save', requestOptions).then(handleResponse);
}

function start(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/start/' + payload, requestOptions).then(handleResponse);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                //localStorage.removeItem('vue-token');
                //location.reload(true);               
            }

            const error = (data && data.message) || response.statusText;
            this.$store.dispatch("alert/error", error);
            //Sentry.captureException(error);
            return Promise.reject(error);
        }
        console.info("Response: " + JSON.stringify(data));
        return data;
    });
}