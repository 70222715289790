<template>
  <v-app>    
    <LayoutDefault></LayoutDefault>

    <div class="text-center ma-2">
      <v-snackbar v-model="snackbar">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
            Schließen
          </v-btn>
        </template>
      </v-snackbar>
    </div>
        <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="520">
        <v-card>
          <v-card-title class="headline">
            {{ dialogTitle }}
          </v-card-title>
          <v-card-text> {{ dialogMsg }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="clear">
              OK
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </v-app>
</template>

<script>
import LayoutDefault from "./layouts/default";

export default {
  name: "app",

  //Components
  components: {
    LayoutDefault
  },

  //Data
  data() {
    return {
      page: {
        url: "",
      },
      snackbarText: "",
      snackbar: false,
    };
  },

  //Lifecycle
  mounted() {
    this.$root.$on("show_snackbar", (msg) => {
      this.snackbarText = msg;
      this.snackbar = true;
    });

    this.$root.$on("show_dialog", (title, msg) => {
      this.$store.dispatch("alert/success", title, msg);
    });
  },

  //Logic
  computed: {
    notifierCookieAvailable: {
      get: function () {
        return localStorage.getItem("noNotification") != 1;
      },
    },
    dialog() {
      let v =
        this.$store.state.alert.message != "" &&
        this.$store.state.alert.message != undefined &&
        this.$store.state.alert.message != null;
      return v;
    },
    getUser() {
      let msg = this.$store.state.authentication.user;
      console.log("User");
      console.log(msg);
      return msg;
    },
    dialogMsg() {
      let msg = this.$store.state.alert.message;
      console.log(msg);
      return msg;
    },
    dialogTitle() {
      return this.$store.state.alert.title;
    },
    alert() {
      return this.$store.state.alert;
    },
    shareRoute() {
      let name = this.$router.currentRoute.path;
      return name.startsWith("/share/");
    },
  },
  methods: {
    clear() {
      this.$store.dispatch("alert/clear");
    },
  },
  watch: {
    $route() {
      this.$store.dispatch("alert/clear");
    },
  },
};
</script>

<style >
#app {
  min-height: 100%;
}

.v-subheader {
  height: 38px !important;
  font-size: 1.2rem !important;
}


.card-text {
  font-family: 'Montserrat', sans-serif;
  font-weight: 600 !important;
  font-size: 16px !important;
}

h2,
h3,
h4,
h5 {
  font-family: 'Montserrat', sans-serif;
  /*font-weight: normal;*/
}

.color_table th {
  background-color: #778a7d !important;
  color: white;
}

.color_table th > span {
  font-weight: bold;
  color: white;
}

.color_table_secondary th {
  background-color: #878787 !important;
  color: white;
}

.color_table_secondary th > span {
  font-weight: bold;
  color: white;
}

.v-input--switch__track,
.v-input--switch__thumb {
  background-color: #778a7d !important;
}

div.col.dense {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
div.row.dense {
  margin-top: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}


@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ 
  .sizes {
    min-width: 320px !important;
    max-width: 400px !important;
  }
  .newline {
    display: block;
  }
  .apptitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: 1000 !important;
    font-size: 20px;
    font-style: normal;
  }
  .appsubtitle {
    font-size: 14px;
    font-style: normal;
  }

}
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ 
  .sizes {
    min-width: 480px !important;
    max-width: 480px !important;
  }
  .newline {
  display: block;
}

.apptitle {
  font-family: 'Montserrat', sans-serif;
  font-weight: 1000 !important;
  font-size: 24px;
  font-style: normal;
}
.appsubtitle {
  font-size: 18px;
  font-style: normal;
}
}
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ 
  .sizes {
    min-width: 600px !important;
    max-width: 600px !important;
  }
}
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ 
  .sizes {
    min-width: 801px !important;
    min-height: 600px !important;
    max-width: 801px !important;
    max-height: 600px !important;
  }
}
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ 
  .sizes {
    min-width: 850px !important;
    min-height: 600px !important;
    max-width: 850px !important;
    max-height: 600px !important;
  }
}
@media (min-width:1281px) { /* hi-res laptops and desktops */ 
  .sizes {
    min-width: 1100px !important;
    min-height: 600px !important;
    max-width: 1100px !important;
    max-height: 600px !important;
  }
}

.newline {
  display: block;
}

</style>