import {
    jwt_decode
} from '../helpers';

export function authHeader() {
    // return authorization header with jwt token
    let token = localStorage.getItem('vue-token');
    return { 'Authorization': 'Bearer ' + token, 'Content-Type': 'application/json'  };
}

export function getUsername() {
    let token = localStorage.getItem('vue-token');
    let decoded = jwt_decode(token);
    return decoded.username;
}

export function getEmail() {
    let token = localStorage.getItem('vue-token');
    let decoded = jwt_decode(token);
    return decoded.email;
}

export function getFirstName() {
    let token = localStorage.getItem('vue-token');
    let decoded = jwt_decode(token);
    return decoded.firstname;
}

export function getLastName() {
    let token = localStorage.getItem('vue-token');
    let decoded = jwt_decode(token);
    return decoded.lastname;
}

export function getInitials() {
    let token = localStorage.getItem('vue-token');
    let decoded = jwt_decode(token);

    let firstLetter = decoded.firstname.charAt(0);
    let secondLetter = decoded.lastname.charAt(0);

    return (firstLetter + secondLetter).toUpperCase();
}

export function authHeaderMultiform() {
    // return authorization header with jwt token
    let token = localStorage.getItem('vue-token');
    return { 'Authorization': 'Bearer ' + token, 'Content-Type': 'multipart/form-data' };
}

export function authHeaderUndefined() {
    // return authorization header with jwt token
    let token = localStorage.getItem('vue-token');
    return { 'Authorization': 'Bearer ' + token };
}

