import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from "../components/views/Home"
import Done from "../components/views/Done"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/done',
    name: 'Done',
    component: Done,
  },
  {
    path: '*',
    redirect: '/',
    meta: {
      isAuthenticated: false
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  console.log("Route to: " + to + " from: " + from);
  next();
})

export default router
