import Vue from 'vue';
import VueJwtDecode from 'vue-jwt-decode'

Vue.use(VueJwtDecode)

export function jwt_decode(jwt) {
    if (jwt == null)
        return;
        
    let jwt_value = jwt;
    try {
        let x = VueJwtDecode.decode(jwt_value)
        return { username: x.preferred_username, email: x.email,  name: x.name, firstname: x.given_name, lastname: x.family_name }
    }
    catch (e) {
        console.log(e);
        return  { username: "error", email: "error", name: "error" }
    }
}