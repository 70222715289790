<template>
  <div>
    <v-container fluid class="fill-height" v-if="isLoading">
      <v-row class="fill-height" align="center" justify="center">
        <v-progress-circular indeterminate color="primary" class="mb-12"></v-progress-circular>
      </v-row>
      <v-row align="center" justify="center">
        <p>Bitte warten...</p>
      </v-row>
    </v-container>
    <v-container fluid class="fill-height" v-else>
      <v-row>
        <v-card flat tile>
          <v-window v-model="onboarding" touchless>
            <v-window-item class="sizes">
              <v-card color="transparent" flat tile style="padding-top: 2rem;">
                <v-row class="fill-height" align="center" justify="center">
                  <v-card-title class="apptitle">
                    <span>In welchem Gebäude soll</span>&nbsp;<span class="newline"> gekühlt werden?</span></v-card-title>
                  <v-card-text class="text-center appsubtitle">
                    * Mieter und WEG benötigen die Genehmigung.
                  </v-card-text>
                  <v-card-text class="text-center">
                    <v-row>
                      <v-col v-for="house in houses" :key="house.id" cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedHouse(house.id)" class="mx-auto" :elevation="hover ? 12 : 2"
                            :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img :src="house.image" width="128" height="128" :style="house.marginStyle"></v-img>
                              <span class="card-text">{{ house.name }}</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>

                    </v-row>
                  </v-card-text>
                </v-row>
              </v-card>
            </v-window-item>

            <v-window-item class="sizes">
              <v-card v-if="isWaiting" style="border: none; margin-top: 3rem; padding-top: 3rem" color="transparent" flat
                tile>
                <v-row class="fill-height" align="center" justify="center">
                  <v-progress-circular indeterminate color="primary" class="mb-12"></v-progress-circular>
                </v-row>
                <v-row align="center" justify="center">
                  <p>Bitte warten...</p>
                </v-row>
              </v-card>
              <v-card color="transparent" flat tile v-else style="padding-top: 2rem;">
                <v-row class="fill-height" align="center" justify="center">
                  <v-card-title class="apptitle">
                    <span>Welche Räume sollen </span>&nbsp;<span class="newline"> gekühlt werden?</span>
                  </v-card-title>

                  <v-card-text class="text-center" style="padding-left: 10%">
                    <v-form ref="form" v-model="formValidation_A" lazy-validation>
                      <v-row>
                        <v-col cols="12" sm="2">
                          <v-subheader class="my-4 ">1. Raum *:</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-text-field name="firstRoomName" v-model="data.firstRoomName" :rules="[
                            () =>
                              !!data.firstRoomName ||
                              'mindestens ein Raum ist erforderlich',
                            () =>
                              (data.firstRoomName || '').length <= 30 ||
                              'max. 30 Zeichen',
                            () =>
                              (data.firstRoomName || '').length >= 3 ||
                              'min. 3 Zeichen',
                          ]" placeholder="Name des Raumes ... z. B. Wohnzimmer" required></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="2">
                          <v-subheader class="my-4">2. Raum:</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-text-field v-model="data.secondRoomName" placeholder="Name des Raumes ... z. B. Schlafzimmer"
                            :disabled="!data.firstRoomName"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="2">
                          <v-subheader class="my-4">3. Raum:</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-text-field v-model="data.thirdRoomName" placeholder="Name des Raumes ... z. B. Kinderzimmer"
                            :disabled="!data.secondRoomName"></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" sm="2">
                          <v-subheader class="my-4">4. Raum:</v-subheader>
                        </v-col>
                        <v-col cols="12" sm="8">
                          <v-text-field v-model="data.fourRoomName" placeholder="Name des Raumes ... z. B. Büro"
                            :disabled="!data.thirdRoomName"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-card-text>
                </v-row>
              </v-card>
            </v-window-item>

            <v-window-item class="sizes">
              <v-card color="transparent" flat tile style="padding-top: 2rem;">
                <v-row class="fill-height" align="center" justify="center">
                  <v-card-title class="apptitle">
                    <span>Welcher Klimaanlagentyp </span>&nbsp;<span class="newline"> (innen) für Raum
                      {{ data.firstRoomName }}</span>
                  </v-card-title>
                  <v-card-text class="text-center appsubtitle">
                    * Mieter und WEG benötigen die Genehmigung.
                  </v-card-text>
                  <v-card-text class="text-center">
                    <v-row>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeFirstRoom(1)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g1.jpg" width="128" height="128"
                                style="margin-left: 2rem"></v-img>
                              <span class="card-text">Wandgerät</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeFirstRoom(2)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g2.jpg" width="128" height="128"
                                style="margin-left: 2rem"></v-img>
                              <span class="card-text">Truhengerät</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeFirstRoom(3)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g3.jpg" width="128" height="128"
                                style="margin-left: 3rem"></v-img>
                              <span class="card-text">Deckengerät</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeFirstRoom(4)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g4.jpg" width="128" height="128"
                                style="margin-left: 3rem"></v-img>
                              <span class="card-text">weiß ich nicht</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-row>
                <Spacer :height="0"></Spacer>
              </v-card>
            </v-window-item>

            <v-window-item class="sizes">
              <v-card color="transparent" flat tile style="padding-top: 2rem;">
                <v-row class="fill-height" align="center" justify="center">
                  <v-card-title class="apptitle">
                    <span>Welcher Klimaanlagentyp</span>&nbsp;<span class="newline"> (innen) für Raum
                      {{ data.secondRoomName }}</span>
                  </v-card-title>
                  <v-card-text class="text-center appsubtitle">
                    * Mieter und WEG benötigen die Genehmigung.
                  </v-card-text>
                  <v-card-text class="text-center">
                    <v-row>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeSecondRoom(1)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g1.jpg" width="128" height="128"
                                style="margin-left: 2rem"></v-img>
                              <span class="card-text">Wandgerät</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeSecondRoom(2)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g2.jpg" width="128" height="128"
                                style="margin-left: 2rem"></v-img>
                              <span class="card-text">Truhengerät</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeSecondRoom(3)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g3.jpg" width="128" height="128"
                                style="margin-left: 3rem"></v-img>
                              <span class="card-text">Deckengerät</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeSecondRoom(4)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g4.jpg" width="128" height="128"
                                style="margin-left: 3rem"></v-img>
                              <span class="card-text">weiß ich nicht</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-row>
                <Spacer :height="0"></Spacer>
              </v-card>
            </v-window-item>

            <v-window-item class="sizes">
              <v-card color="transparent" flat tile style="padding-top: 2rem;">
                <v-row class="fill-height" align="center" justify="center">
                  <v-card-title class="apptitle">
                    <span>Welcher Klimaanlagentyp</span>&nbsp;<span class="newline"> (innen) für Raum
                      {{ data.thirdRoomName }}</span>
                  </v-card-title>
                  <v-card-text class="text-center appsubtitle">
                    * Mieter und WEG benötigen die Genehmigung.
                  </v-card-text>
                  <v-card-text class="text-center">
                    <v-row>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeThirdRoom(1)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g1.jpg" width="128" height="128"
                                style="margin-left: 2rem"></v-img>
                              <span class="card-text">Wandgerät</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeThirdRoom(2)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g2.jpg" width="128" height="128"
                                style="margin-left: 2rem"></v-img>
                              <span class="card-text">Truhengerät</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeThirdRoom(3)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g3.jpg" width="128" height="128"
                                style="margin-left: 3rem"></v-img>
                              <span class="card-text">Deckengerät</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeThirdRoom(4)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g4.jpg" width="128" height="128"
                                style="margin-left: 3rem"></v-img>
                              <span class="card-text">weiß ich nicht</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-row>
                <Spacer :height="0"></Spacer>
              </v-card>
            </v-window-item>

            <v-window-item class="sizes">
              <v-card color="transparent" flat tile style="padding-top: 2rem;">
                <v-row class="fill-height" align="center" justify="center">
                  <v-card-title class="apptitle">
                    <span>Welcher Klimaanlagentyp</span>&nbsp;<span class="newline"> (innen) für Raum
                      {{ data.fourRoomName }}</span>
                  </v-card-title>
                  <v-card-text class="text-center appsubtitle">
                    * Mieter und WEG benötigen die Genehmigung.
                  </v-card-text>
                  <v-card-text class="text-center">
                    <v-row>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeFourRoom(1)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g1.jpg" width="128" height="128"
                                style="margin-left: 2rem"></v-img>
                              <span class="card-text">Wandgerät</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeFourRoom(2)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g2.jpg" width="128" height="128"
                                style="margin-left: 2rem"></v-img>
                              <span class="card-text">Truhengerät</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeFourRoom(3)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g3.jpg" width="128" height="128"
                                style="margin-left: 3rem"></v-img>
                              <span class="card-text">Deckengerät</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                      <v-col cols="12" sm="6" md="3">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeFourRoom(4)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img src="@/assets/slide_002_g4.jpg" width="128" height="128"
                                style="margin-left: 3rem"></v-img>
                              <span class="card-text">weiß ich nicht</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-row>
                <Spacer :height="0"></Spacer>

              </v-card>
            </v-window-item>

            <v-window-item class="sizes">
              <v-card color="transparent" flat tile style="padding-top: 2rem;">
                <v-row class="fill-height" align="center" justify="center">
                  <v-card-title class="apptitle">
                    <span>Wie groß ist das Volumen</span>&nbsp;<span class="newline"> der jeweiligen
                      Räume?</span></v-card-title>
                  <v-card-text class="text-center " style="padding-top: 1rem;">
                    <v-row class="dense hidden-sm-and-down">
                      <v-col cols="12" sm="3" class="dense"> </v-col>
                      <v-col cols="12" sm="3" class="dense"> <b>Länge</b> </v-col>
                      <v-col cols="12" sm="3" class="dense"> <b>Breite</b> </v-col>
                      <v-col cols="12" sm="3" class="dense"> <b>Höhe</b> </v-col>
                    </v-row>

                    <v-form v-model="formValidation_B">
                      <!-- ROOM 1 -->
                      <v-row class="dense">
                        <v-col cols="12" sm="3" class="dense">
                          <v-subheader class="my-4">{{
                            data.firstRoomName
                          }}</v-subheader>
                        </v-col>
                        <v-col cols="12" class="dense hidden-sm-and-up">
                          <b>Länge</b>
                        </v-col>
                        <v-col cols="12" sm="3" style="padding-right: 1rem" class="dense">
                          <v-text-field v-model="data.firstRoomLength" placeholder="z.B. 2,5 m"
                            :disabled="!data.firstRoomName" :rules="[
                              () =>
                                isNaN(data.firstRoomLength.replace(',', '.')) === false ||
                                'Bitte geben Sie eine Zahl ein',
                              () => data.firstRoomLength.replace(',', '.') <= 100 || 'max. 100 m',
                              () => data.firstRoomLength.replace(',', '.') >= 1 || 'min. 1 m',
                            ]" suffix="m"></v-text-field>
                        </v-col>

                        <v-col cols="12" class="dense hidden-sm-and-up">
                          <b>Breite</b>
                        </v-col>
                        <v-col cols="12" sm="3" style="padding-right: 1rem" class="dense">
                          <v-text-field v-model="data.firstRoomWidth" placeholder="z.B. 5 m"
                            :disabled="!data.firstRoomName" suffix="m" :rules="[
                              () =>
                                isNaN(data.firstRoomWidth.replace(',', '.')) === false ||
                                'Bitte geben Sie eine Zahl ein',
                              () => data.firstRoomWidth.replace(',', '.') <= 100 || 'max. 100 m',
                              () => data.firstRoomWidth.replace(',', '.') >= 1 || 'min. 1 m',
                            ]"></v-text-field>
                        </v-col>

                        <v-col cols="12" class="dense hidden-sm-and-up">
                          <b>Höhe</b>
                        </v-col>
                        <v-col cols="12" sm="3" style="padding-right: 1rem" class="dense">
                          <v-text-field v-model="data.firstRoomHeight" placeholder="z.B. 3 m"
                            :disabled="!data.firstRoomName" suffix="m" :rules="[
                              () =>
                                isNaN(data.firstRoomHeight.replace(',', '.')) === false ||
                                'Bitte geben Sie eine Zahl ein',
                              () => data.firstRoomHeight.replace(',', '.') <= 10 || 'max. 10 m',
                              () => data.firstRoomHeight.replace(',', '.') >= 2 || 'min. 2 m',
                            ]"></v-text-field>
                        </v-col>
                      </v-row>

                      <!-- ROOM 2 -->
                      <v-row :disabled="!data.secondRoomName" class="dense">
                        <v-col cols="12" sm="3" class="dense">
                          <v-subheader class="my-4">{{
                            data.secondRoomName
                          }}</v-subheader>
                        </v-col>
                        <v-col cols="12" class="dense hidden-sm-and-up" v-show="data.secondRoomName">
                          <b>Länge</b>
                        </v-col>

                        <v-col cols="12" sm="3" style="padding-right: 1rem" class="dense">
                          <v-text-field v-show="data.secondRoomName" v-model="data.secondRoomLength"
                            placeholder="z.B. 2,5 m" :disabled="!data.secondRoomName" suffix="m"></v-text-field>
                        </v-col>

                        <v-col cols="12" class="dense hidden-sm-and-up" v-show="data.secondRoomName">
                          <b>Breite</b>
                        </v-col>
                        <v-col cols="12" sm="3" style="padding-right: 1rem" class="dense">
                          <v-text-field v-show="data.secondRoomName" v-model="data.secondRoomWidth" placeholder="z.B. 5 m"
                            :disabled="!data.secondRoomName" suffix="m"></v-text-field>
                        </v-col>

                        <v-col cols="12" class="dense hidden-sm-and-up" v-show="data.secondRoomName">
                          <b>Höhe</b>
                        </v-col>
                        <v-col cols="12" sm="3" style="padding-right: 1rem" class="dense">
                          <v-text-field v-show="data.secondRoomName" v-model="data.secondRoomHeight"
                            placeholder="z.B. 3 m" :disabled="!data.secondRoomName" suffix="m"></v-text-field>
                        </v-col>
                      </v-row>

                      <!-- ROOM 2 -->
                      <v-row :disabled="!data.thirdRoomName" class="dense">
                        <v-col cols="12" sm="3" class="dense">
                          <v-subheader class="my-4">{{
                            data.thirdRoomName
                          }}</v-subheader>
                        </v-col>

                        <v-col cols="12" class="dense hidden-sm-and-up" v-show="data.thirdRoomName">
                          <b>Länge</b>
                        </v-col>
                        <v-col cols="12" sm="3" style="padding-right: 1rem" class="dense">
                          <v-text-field v-show="data.thirdRoomName" v-model="data.thirdRoomLength"
                            placeholder="z.B. 2,5 m" :disabled="!data.thirdRoomName" suffix="m"></v-text-field>
                        </v-col>

                        <v-col cols="12" class="dense hidden-sm-and-up" v-show="data.thirdRoomName">
                          <b>Breite</b>
                        </v-col>
                        <v-col cols="12" sm="3" style="padding-right: 1rem" class="dense">
                          <v-text-field v-show="data.thirdRoomName" v-model="data.thirdRoomWidth" placeholder="z.B. 5 m"
                            :disabled="!data.thirdRoomName" suffix="m"></v-text-field>
                        </v-col>

                        <v-col cols="12" class="dense hidden-sm-and-up" v-show="data.thirdRoomName">
                          <b>Höhe</b>
                        </v-col>
                        <v-col cols="12" sm="3" style="padding-right: 1rem" class="dense">
                          <v-text-field v-show="data.thirdRoomName" v-model="data.thirdRoomHeight" placeholder="z.B. 3 m"
                            :disabled="!data.thirdRoomName" suffix="m"></v-text-field>
                        </v-col>
                      </v-row>

                      <!-- ROOM 2 -->
                      <v-row :disabled="!data.fourRoomName" class="dense">
                        <v-col cols="12" sm="3" class="dense">
                          <v-subheader class="my-4">{{
                            data.fourRoomName
                          }}</v-subheader>
                        </v-col>

                        <v-col cols="12" class="dense hidden-sm-and-up" v-show="data.fourRoomName">
                          <b>Länge</b>
                        </v-col>
                        <v-col cols="12" sm="3" style="padding-right: 1rem" class="dense">
                          <v-text-field v-show="data.fourRoomName" v-model="data.fourRoomLength" placeholder="z.B. 2,5 m"
                            :disabled="!data.fourRoomName" suffix="m"></v-text-field>
                        </v-col>

                        <v-col cols="12" class="dense hidden-sm-and-up" v-show="data.fourRoomName">
                          <b>Breite</b>
                        </v-col>
                        <v-col cols="12" sm="3" style="padding-right: 1rem" class="dense">
                          <v-text-field v-show="data.fourRoomName" v-model="data.fourRoomWidth" placeholder="z.B. 5 m"
                            :disabled="!data.fourRoomName" suffix="m"></v-text-field>
                        </v-col>

                        <v-col cols="12" class="dense hidden-sm-and-up" v-show="data.fourRoomName">
                          <b>Höhe</b>
                        </v-col>
                        <v-col cols="12" sm="3" style="padding-right: 1rem" class="dense">
                          <v-text-field v-show="data.fourRoomName" v-model="data.fourRoomHeight" placeholder="z.B. 3 m"
                            :disabled="!data.fourRoomName" suffix="m"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                    <!-- Repeat similar structure for ROOM 3 and ROOM 4 -->
                  </v-card-text>
                </v-row>
              </v-card>
            </v-window-item>

            <v-window-item class="sizes">
              <v-card color="transparent" flat tile style="padding-top: 2rem;">
                <v-row class="fill-height" align="center" justify="center">
                  <v-card-title class="apptitle">
                    <span>Wo soll das Außengerät</span>&nbsp;<span class="newline"> angebracht werden?</span></v-card-title>

                  <v-card-text class="text-center" style="padding-top: 4rem;">
                    <v-row>
                      <v-col v-for="placement in placements" :key="placement.id">
                        <v-hover v-slot="{ hover }">
                          <v-card @click="selectedTypeOuter(placement.id)" class="mx-auto" max-width="344"
                            :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                            <v-card-text
                              style="display: flex; flex-direction: column; align-items: center; text-align: center;">
                              <v-img :src="placement.image" width="128" height="128"
                                :style="placement.marginStyle"></v-img>
                              <span class="card-text">{{ placement.name }}</span>
                            </v-card-text>
                          </v-card>
                        </v-hover>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-row>
                <Spacer :height="0"></Spacer>
              </v-card>
            </v-window-item>

            <v-window-item class="sizes">
              <v-card color="transparent" flat tile style="padding-top: 2rem; width: 100%;">
                <v-row class="fill-height" align="center" justify="center">
                  <v-card-title class="apptitle">
                    <span>Wie weit ist das </span>&nbsp;<span class="newline"> jeweilige</span>&nbsp;<span
                      class="newline"> Innengerät vom</span>&nbsp;
                    <span class="newline">
                      Außengerät </span>&nbsp;
                    <span class="newline">entfernt?</span>
                  </v-card-title>
                  <v-card-text class="text-center appsubtitle">
                    * Laufweg in Meter
                  </v-card-text>
                  <v-form v-model="formValidation_C">
                    <v-card-text class="text-center">
                      <v-row row :disabled="!data.firstRoomName">
                        <v-col>
                          <v-subheader class="my-4">{{
                            data.firstRoomName
                          }}</v-subheader>
                        </v-col>
                        <v-col style="padding-right: 1rem">
                          <v-text-field v-show="data.firstRoomName" v-model="data.firstRoomDistance"
                            placeholder="z.B. 2,5 m" :disabled="!data.firstRoomName" :rules="[
                              () =>
                                isNaN(data.firstRoomDistance.replace(',', '.')) === false ||
                                'Bitte geben Sie eine Zahl ein',
                              () => data.firstRoomDistance.replace(',', '.') <= 20 || 'max. 20 m',
                              () => data.firstRoomDistance.replace(',', '.') >= 1 || 'min. 1 m',
                            ]" suffix="m"></v-text-field>
                        </v-col>
                        <v-col style="padding-right: 1rem">
                          <v-subheader class="my-4" v-show="data.firstRoomName">bis zum Außengerät.</v-subheader>
                        </v-col>
                      </v-row>

                      <v-row row :disabled="!data.secondRoomName">
                        <v-col>
                          <v-subheader class="my-4">{{
                            data.secondRoomName
                          }}</v-subheader>
                        </v-col>
                        <v-col style="padding-right: 1rem">
                          <v-text-field v-show="data.secondRoomName" v-model="data.secondRoomDistance"
                            placeholder="z.B. 2,5 m" :disabled="!data.secondRoomName" :rules="[
                              () =>
                                isNullOrEmpty(data.secondRoomName) ||
                                data.secondRoomDistance.replace(',', '.') <= 20 ||
                                'max. 200 m',
                              () =>
                                isNullOrEmpty(data.secondRoomName) ||
                                data.secondRoomDistance.replace(',', '.') >= 1 ||
                                'min. 1 m',
                            ]" suffix="m"></v-text-field>
                        </v-col>
                        <v-col style="padding-right: 1rem">
                          <v-subheader class="my-4" v-show="data.secondRoomName">bis zum Außengerät.</v-subheader>
                        </v-col>
                      </v-row>

                      <v-row row :disabled="!data.thirdRoomName">
                        <v-col>
                          <v-subheader class="my-4">{{
                            data.thirdRoomName
                          }}</v-subheader>
                        </v-col>
                        <v-col style="padding-right: 1rem">
                          <v-text-field v-show="data.thirdRoomName" v-model="data.thirdRoomDistance"
                            placeholder="z.B. 2,5 m" :disabled="!data.thirdRoomName" :rules="[
                              () =>
                                isNullOrEmpty(data.thirdRoomName) ||
                                data.thirdRoomDistance.replace(',', '.') <= 20 ||
                                'max. 200 m',
                              () =>
                                isNullOrEmpty(data.thirdRoomName) ||
                                data.thirdRoomDistance.replace(',', '.') >= 1 ||
                                'min. 1 m',
                            ]" suffix="m"></v-text-field>
                        </v-col>
                        <v-col style="padding-right: 1rem">
                          <v-subheader class="my-4" v-show="data.thirdRoomName">bis zum Außengerät.</v-subheader>
                        </v-col>
                      </v-row>

                      <v-row row :disabled="!data.fourRoomName">
                        <v-col>
                          <v-subheader class="my-4">{{
                            data.fourRoomName
                          }}</v-subheader>
                        </v-col>
                        <v-col>
                          <v-text-field v-show="data.fourRoomName" v-model="data.fourRoomDistance"
                            placeholder="z.B. 2,5 m" :disabled="!data.fourRoomName" :rules="[
                              () =>
                                isNullOrEmpty(data.fourRoomName) ||
                                data.fourRoomDistance.replace(',', '.') <= 20 ||
                                'max. 200 m',
                              () =>
                                isNullOrEmpty(data.fourRoomName) ||
                                data.fourRoomDistance.replace(',', '.') >= 1 ||
                                'min. 1 m',
                            ]" suffix="m"></v-text-field>
                        </v-col>
                        <v-col>
                          <v-subheader class="my-4" v-show="data.fourRoomName">bis zum Außengerät.</v-subheader>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-form>
                </v-row>
              </v-card>
            </v-window-item>

            <v-window-item class="sizes">
              <v-card color="transparent" flat tile style="padding-top: 2rem;">
                <v-row class="fill-height" align="center" justify="center">
                  <v-card-title class="apptitle">
                    <span>Wie sieht die komplette</span>&nbsp;<span class="newline"> Wand aus, wo die</span>&nbsp;<span
                      class="newline"> Klimaanlage hin
                      soll?</span>
                  </v-card-title>
                  <v-card-text class="text-center appsubtitle">
                    <span>* Dies ist freiwillig, es hilft uns</span>&nbsp;<span class="newline"> natürlich ein besseres
                      Angebot zu verfassen. </span>&nbsp;<span class="newline"> Die Bilder werden nur für die
                      Erstellung des Angebots benutzt.</span>
                  </v-card-text>
                  <v-card-text>
                    <v-row>
                      <v-col>
                        <v-card class="mx-auto" max-width="344">
                          <v-card-text v-if="successfulUpload">
                            <h2>Bild erfolgreich hochgeladen.</h2>
                            <v-icon>mdi-check</v-icon>
                          </v-card-text>
                          <v-card-text v-else>
                            <v-form @submit.prevent="newImage" enctype="multipart/form-data" id="upload-form">
                              <v-row>
                                <v-col cols="12">
                                  <v-file-input accept="image/png, image/jpeg, image/bmp" name="file" v-model="file"
                                    label="Datei Upload" filled prepend-icon="mdi-camera"
                                    multiple="multiple"></v-file-input>

                                  <div v-show="submitted && !file" class="invalid-feedback">
                                    Bitte Datei auswählen
                                  </div>
                                  <v-row>
                                    <v-col cols="12">
                                      <v-btn type="submit" form="upload-form" color="primary"
                                        :disabled="submitted || !file">
                                        Hochladen...
                                      </v-btn>
                                      <v-progress-circular indeterminate color="primary"
                                        v-if="submitted"></v-progress-circular>
                                    </v-col>
                                  </v-row>
                                  <v-row>
                                    <v-col cols="12">
                                      <ul style="list-style: none">
                                        <li v-for="s in uploadSuccesses" :key="s.item" class="successfull">
                                          Erfolgreich: {{ s }}
                                        </li>
                                        <li v-for="s in uploadErrors" :key="s.item" class="failed">
                                          Fehlgeschlagen: {{ s }}
                                        </li>
                                      </ul>
                                    </v-col>
                                  </v-row>
                                </v-col>
                              </v-row>
                            </v-form>

                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-row>
                <Spacer :height="0"></Spacer>
              </v-card>
            </v-window-item>

            <v-window-item class="sizes">
              <v-card color="transparent" flat tile style="padding-top: 2rem;">
                <v-row class="fill-height" align="center" justify="center">
                  <v-card-title class="apptitle">
                    Wolf Kältetechnik GmbH<br />
                    Ihre neue Klimaanlage
                  </v-card-title>

                  <v-card-text class="text-center">
                    <v-form v-model="formValidation_Z">
                      <v-row class="dense">
                        <v-col class="dense">
                          <v-radio-group row v-model="data.genderType">
                            <v-radio label="Herr" value="1"></v-radio>
                            <v-radio label="Frau" value="2"></v-radio>
                            <v-radio label="Divers" value="3"></v-radio>
                            <v-radio label="Firma" value="4"></v-radio>
                          </v-radio-group>
                        </v-col>
                      </v-row>

                      <v-row class="dense" style="padding-top: 2rem !important">
                        <v-col class="dense">
                          <v-text-field v-model="data.contactFirstName" placeholder="Vorname" :rules="[
                            () =>
                              !!data.contactFirstName ||
                              'Vorname ist ein Pflichtfeld',
                          ]" prepend-icon="mdi-account-box"></v-text-field>
                        </v-col>
                        <v-col class="dense">
                          <v-card-subtitle>Für ein genaues Angebot benötigen wir Ihre
                            Adresse:</v-card-subtitle>
                        </v-col>
                      </v-row>
                      <v-row class="dense">
                        <v-col class="dense">
                          <v-text-field v-model="data.contactLastName" :rules="[
                            () =>
                              !!data.contactLastName ||
                              'Nachname ist ein Pflichtfeld',
                          ]" placeholder="Nachname" prepend-icon="mdi-account-box"></v-text-field>
                        </v-col>
                        <v-col class="dense">
                          <v-text-field v-model="data.contactStreet" :rules="[
                            () =>
                              !!data.contactLastName ||
                              'Straße und Hausnummer sind ein Pflichtfeld',
                          ]" placeholder="Straße Nr.*" prepend-icon="mdi-home"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="dense">
                        <v-col class="dense">
                          <v-text-field v-model="data.contactEMail" placeholder="E-Mail Adresse*" :rules="[
                            () =>
                              !!data.contactEMail ||
                              'E-Mail Adresse ist ein Pflichtfeld',
                            () =>
                              /.+@.+\..+/.test(data.contactEMail) ||
                              'E-Mail muss gültig sein',
                          ]" prepend-icon="mdi-email"></v-text-field>
                        </v-col>
                        <v-col class="dense">
                          <v-text-field v-model="data.contactZipCode" :rules="[
                            () =>
                              !!data.contactZipCode ||
                              'PLZ ist ein Pflichtfeld',
                            () =>
                              isNaN(data.contactZipCode) === false ||
                              'PLZ darf nur Zahlen beinhalten',
                            () =>
                              data.contactZipCode.length == 5 ||
                              'PLZ darf nur 5 Zeichen groß sein',
                          ]" placeholder="PLZ*" prepend-icon="mdi-home"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row class="dense">
                        <v-col class="dense">
                          <v-text-field v-model="data.contactPhone" placeholder="Telefonnummer"
                            prepend-icon="mdi-phone"></v-text-field>
                        </v-col>
                        <v-col class="dense">
                          <v-text-field v-model="data.contactCity" :rules="[
                            () =>
                              !!data.contactCity || 'Ort ist ein Pflichtfeld',
                          ]" placeholder="Ort*" prepend-icon="mdi-home"></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="3"></v-col>
                        <v-col>
                          <v-checkbox v-model="privacyChecked">
                            <template v-slot:label>
                              <div>
                                Ich stimme den
                                <v-tooltip location="bottom">
                                  <template v-slot:activator="{ props }">
                                    <a target="_blank" href="https://www.kaeltewolf.de/datenschutz.html" v-bind="props"
                                      @click.stop>
                                      Datenschutzbestimmungen</a>
                                  </template>
                                </v-tooltip>
                                zu *
                              </div>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </v-row>
                    </v-form>
                    <v-row>
                      <v-col>
                        <v-btn color="primary" @click="submitData" :disabled="!formValidation_Z || !privacyChecked">
                          Angebot anfordern
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>

                </v-row>
              </v-card>
            </v-window-item>
          </v-window>
          <v-card-actions class="justify-space-between">
            <v-btn text @click="prev(getPrevSlideValue())" v-if="prevClickable">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-progress-linear color="blue" height="10" :value="(onboarding / length) * 100" rounded></v-progress-linear>
            <v-btn text @click="next(getNextSlideValue())" v-if="nextClickable">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>

    <v-alert v-if="DEBUG" color="secondary">
      Token {{ myToken }} - IsLoading: {{ isLoading }} - Slide {{ onboarding }} </v-alert>
    <v-alert v-if="DEBUG" color="primary">
      <b>DATA:</b>{{ data }}</v-alert>

    <v-alert v-if="DEBUG" color="blue">
      <b>FILE:</b>{{ file }}</v-alert>

    <v-alert v-if="DEBUG" color="blue">
      <b>FormData:</b>{{ files }}</v-alert>
  </div>
</template>

<script>
import moment from "moment";
import Spacer from "../views/Spacer.vue";

export default {
  created() {
    this.reload();
  },
  mounted() {
    this.$root.$on("reload", () => {
      this.reload();
    });
  },
  components: {
    Spacer,
  },
  data() {
    return {
      DEBUG: false,
      submitted: false,
      isWaiting: false,
      privacyChecked: false,
      token: "",
      previewImage: "",
      formValidation_A: false,
      formValidation_B: false,
      formValidation_C: false,
      formValidation_Z: false,

      successfulUpload: false,

      sliderHeight: 420,
      sliderWidth: 960,
      onboarding: 0,
      length: 10,
      placements: [
        {
          id: 1,
          name: "am Boden",
          image: require("@/assets/slide_003_b.jpg"),
          marginStyle: "margin-left: 2rem",
        },
        {
          id: 2,
          name: "Hauswand (bis 3m Höhe)",
          image: require("@/assets/slide_003_w.jpg"),
          marginStyle: "margin-left: 2rem",
        },
        {
          id: 3,
          name: "Auf der Garage",
          image: require("@/assets/slide_003_g.jpg"),
          marginStyle: "margin-left: 3rem",
        },
        {
          id: 4,
          name: "Balkon",
          image: require("@/assets/slide_003_t.jpg"),
          marginStyle: "margin-left: 3rem",
        },
      ],
      houses: [
        {
          id: 1,
          name: "Einfamilienhaus",
          image: require("@/assets/slide_001_h1.jpg"),
          marginStyle: "margin-left: 2rem",
        },
        {
          id: 2,
          name: "Mehrfamilienhaus",
          image: require("@/assets/slide_001_h3.jpg"),
          marginStyle: "margin-left: 3rem",
        },
        {
          id: 3,
          name: "Wohnung",
          image: require("@/assets/slide_001_h4.jpg"),
          marginStyle: "margin-left: 3rem",
        },
        {
          id: 4,
          name: "Gewerbe",
          image: require("@/assets/slide_001_h5.jpg"),
          marginStyle: "margin-left: 2rem",
        },
      ],
      data: {
        token: null,
        id: null,
        started: false,
        finished: false,
        startedAt: null,
        finishedAt: null,
        houseType: null,
        firstRoomName: "",
        firstRoomIndoorType: null,
        firstRoomLength: null,
        firstRoomWidth: null,
        firstRoomHeight: null,
        firstRoomDistance: null,
        secondRoomName: "",
        secondRoomIndoorType: null,
        secondRoomLength: null,
        secondRoomWidth: null,
        secondRoomHeight: null,
        secondRoomDistance: null,
        thirdRoomName: "",
        thirdRoomIndoorType: null,
        thirdRoomLength: null,
        thirdRoomWidth: null,
        thirdRoomHeight: null,
        thirdRoomDistance: null,
        fourRoomName: "",
        fourRoomIndoorType: null,
        fourRoomLength: null,
        fourRoomWidth: null,
        fourRoomHeight: null,
        fourRoomDistance: null,
        outerType: null,
        genderType: "1",
        contactFirstName: null,
        contactLastName: null,
        contactEMail: null,
        contactPhone: null,
        contactStreet: null,
        contactZipCode: null,
        contactCity: null,
      },
      file: null,
      files: new FormData(),
      sex: [
        { text: "Männlich", value: 0 },
        { text: "Weiblich", value: 1 },
        { text: "Divers", value: 2 },
      ],
      confirmBar: false,
      countries: [
        { text: "Deutschland", value: "DE" },
        { text: "Österreich", value: "AT" },
        { text: "Schweiz", value: "CH" },
      ],
    };
  },
  computed: {
    myToken() {
      return this.$store.state.offers.myself.token;
    },
    prevClickable() {
      if (this.onboarding == 0) {
        return false;
      } else {
        return true;
      }
    },
    nextClickable() {
      if (this.onboarding === this.length) {
        return false;
      } else if (this.onboarding === 0) {
        return false;
      } else if (this.onboarding === 9) {
        return true;
      } else if (this.onboarding === 1) {
        return this.data.firstRoomName !== "" && this.formValidation_A;
      } else if (this.onboarding === 6) {
        if (this.formValidation_B) {
          return (
            (!this.data.firstRoomName ||
              (this.data.firstRoomHeight &&
                this.data.firstRoomLength &&
                this.data.firstRoomWidth)) &&
            (!this.data.secondRoomName ||
              (this.data.secondRoomHeight &&
                this.data.secondRoomLength &&
                this.data.secondRoomWidth)) &&
            (!this.data.thirdRoomName ||
              (this.data.thirdRoomHeight &&
                this.data.thirdRoomLength &&
                this.data.thirdRoomWidth)) &&
            (!this.data.fourRoomName ||
              (this.data.fourRoomHeight &&
                this.data.fourRoomLength &&
                this.data.fourRoomWidth))
          );
        }
      } else if (this.onboarding === 8) {
        if (this.formValidation_C) {
          return (
            (!this.data.firstRoomName || this.data.firstRoomDistance) &&
            (!this.data.secondRoomName || this.data.secondRoomDistance) &&
            (!this.data.thirdRoomName || this.data.thirdRoomDistance) &&
            (!this.data.fourRoomName || this.data.fourRoomDistance)
          );
        }
      }
      return false;
    },
    isLoading() {
      return this.$store.state.offers.myself.loading;
    },
    uploadSuccesses() {
      let x = this.$store.state.offers.uploaded;
      return x;
    },
    uploadErrors() {
      return this.$store.state.offers.failed;
    },
  },
  methods: {
    onFileChange() {
      this.file = this.$refs.file.files[0];
      console.info(this.file)
      this.newImage();
    },
    async reload() {
      if (this.myToken == null) {
        console.log("Get Token");
        this.$store.dispatch("offers/init").then(() => {
          this.token = this.$store.state.offers.myself.token;
        });
      }
    },
    submitData() {
      this.$root.$emit("show_snackbar", "Angebot wird erstellt...");

      this.data.token = this.myToken;

      this.data.firstRoomLength = this.data.firstRoomLength ? this.data.firstRoomLength.replace(",", ".") : null;
      this.data.firstRoomWidth = this.data.firstRoomWidth ? this.data.firstRoomWidth.replace(",", ".") : null;
      this.data.firstRoomHeight = this.data.firstRoomHeight ? this.data.firstRoomHeight.replace(",", ".") : null;
      this.data.firstRoomDistance = this.data.firstRoomDistance ? this.data.firstRoomDistance.replace(",", ".") : null;

      this.data.secondRoomLength = this.data.secondRoomLength ? this.data.secondRoomLength.replace(",", ".") : null;
      this.data.secondRoomWidth = this.data.secondRoomWidth ? this.data.secondRoomWidth.replace(",", ".") : null;
      this.data.secondRoomHeight = this.data.secondRoomHeight ? this.data.secondRoomHeight.replace(",", ".") : null;
      this.data.secondRoomDistance = this.data.secondRoomDistance ? this.data.secondRoomDistance.replace(",", ".") : null;

      this.data.thirdRoomLength = this.data.thirdRoomLength ? this.data.thirdRoomLength.replace(",", ".") : null;
      this.data.thirdRoomWidth = this.data.thirdRoomWidth ? this.data.thirdRoomWidth.replace(",", ".") : null;
      this.data.thirdRoomHeight = this.data.thirdRoomHeight ? this.data.thirdRoomHeight.replace(",", ".") : null;
      this.data.thirdRoomDistance = this.data.thirdRoomDistance ? this.data.thirdRoomDistance.replace(",", ".") : null;

      this.data.fourRoomLength = this.data.fourRoomLength ? this.data.fourRoomLength.replace(",", ".") : null;
      this.data.fourRoomWidth = this.data.fourRoomWidth ? this.data.fourRoomWidth.replace(",", ".") : null;
      this.data.fourRoomHeight = this.data.fourRoomHeight ? this.data.fourRoomHeight.replace(",", ".") : null;
      this.data.fourRoomDistance = this.data.fourRoomDistance ? this.data.fourRoomDistance.replace(",", ".") : null;

      this.$store
        .dispatch("offers/createOffer", this.data)
        .then(() => {
          this.$root.$emit("show_snackbar", "Angebot wurde erstellt.");
          this.$store.dispatch("offers/resetUploads");
          this.$router.push({ name: "Done" });
        })
        .catch(() => {
          this.$root.$emit(
            "show_snackbar",
            "Fehler beim Erstellen des Angebots. Bitte versuchen Sie es erneut."
          );
        });
    },
    isEMailAddressValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    isNullOrEmpty(value) {
      return value == null || value == "";
    },
    selectedTypeOuter(type) {
      this.data.outerType = type;
      this.next(1);
    },
    selectedTypeFourRoom(type) {
      this.data.fourRoomIndoorType = type;
      this.next(this.getNextSlideValue());
    },
    selectedTypeThirdRoom(type) {
      this.data.thirdRoomIndoorType = type;
      this.next(this.getNextSlideValue());
    },
    selectedTypeSecondRoom(type) {
      this.data.secondRoomIndoorType = type;
      this.next(this.getNextSlideValue());
    },
    selectedTypeFirstRoom(type) {
      this.data.firstRoomIndoorType = type;
      this.next(this.getNextSlideValue());
    },
    selectedHouse(houseType) {
      this.data.houseType = houseType;
      this.next(1);
    },
    getPrevSlideValue() {
      if (this.onboarding === 6) {
        if (this.data.fourRoomName !== "") {
          return 1;
        } else if (this.data.thirdRoomName !== "") {
          return 2;
        } else if (this.data.secondRoomName !== "") {
          return 3;
        } else if (this.data.firstRoomName !== "") {
          return 4;
        }
      }
      return 1;
    },
    getNextSlideValue() {
      if (this.onboarding === 2 && !this.data.secondRoomName) {
        return 4;
      } else if (this.onboarding === 3 && !this.data.thirdRoomName) {
        return 3;
      } else if (this.onboarding === 4 && !this.data.fourRoomName) {
        return 2;
      }
      return 1;
    },
    async next(slides = 1) {
      if (this.onboarding === 0) {
        this.data.started = true;
        this.data.startedAt = moment().format("YYYY-MM-DD HH:mm:ss");

        this.isWaiting = true;
        this.$store.dispatch("offers/start", this.myToken).then(() => {
          this.isWaiting = false;
        });
      }
      this.onboarding += slides;
    },
    prev(slides = 1) {
      this.onboarding -= slides;
    },
    parseDate(date) {
      if (date) {
        let dateCorrect = null;
        dateCorrect = date.replace("+0100 +0100", "");
        dateCorrect = dateCorrect.replace("+0000 UTC", "");
        dateCorrect = dateCorrect.replace(" ", "T").trim() + "Z";
        console.log(dateCorrect);
        let formatted = moment(dateCorrect).format("YYYY-MM-DD");
        return formatted;
      } else {
        return "";
      }
    },
    newImage() {
      for (let f of this.file) {
        this.$root.$emit(
          "show_snackbar",
          "Bitte warten. Bild wird hochgeladen: " + f.name
        );

        let formData = new FormData();
        formData.append("file", f);
        formData.append("token", this.myToken);

        console.info(formData);

        this.$store
          .dispatch("offers/updatePicture", formData)
          .then(() => {
            this.$root.$emit(
              "show_snackbar",
              "Bild wurde hochgeladen: " + f.name
            );
            this.successfulUpload = true;
            this.$store.dispatch("offers/resetUploads");
          })
          .catch(() => {
            this.$root.$emit(
              "show_snackbar",
              "Fehler beim Hochladen des Bildes. Passen Sie die Größe an und versuchen Sie es erneut. Danke."
            );
          });
      }

      this.reload();
    },
  },
};
</script>
