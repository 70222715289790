import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'


import VTiptap from "@peepi/vuetify-tiptap";

Vue.directive('plaintext', {
  bind(el, binding, vnode) {
    console.log(binding);
    console.log(vnode);
    el.innerHTML = el.innerText;
    //el.innerHTML = el.innerHTML.replace(/<[^>]+>/gm, '');
  }
});

Vue.use(VTiptap);

import VueJwtDecode from 'vue-jwt-decode'

Vue.use(VueJwtDecode)
Vue.use(vuetify)

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
}).$mount('#app')